import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = (ref: MutableRefObject<any>, callback: (e: MouseEvent) => void) => {
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as HTMLInputElement)) {
                callback(e);
            }
        };

        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    }, [ref, callback]);
};

export default useOutsideClick;
