import { useEffect, useState } from 'react';

export default function useMediaQuery(mediaQuery: MediaQueryList): boolean {
    const [isMatching, setIsMatching] = useState<boolean>(mediaQuery.matches);

    useEffect(() => {
        const onChange = () => setIsMatching(mediaQuery.matches);

        mediaQuery.addListener(onChange);
        onChange();

        return () => mediaQuery.removeListener(onChange);
    }, [mediaQuery]);

    return isMatching;
}
