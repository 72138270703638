import matchMedia from '../utils/match-media';

export const minWidth = matchMedia('(max-width: 476px)') as MediaQueryList;
export const minSmall = matchMedia('(max-width: 820px)') as MediaQueryList;
export const minMed = matchMedia('(max-width: 650px') as MediaQueryList;
export const xSmallViewportWidth = matchMedia('(min-width: 576px)') as MediaQueryList;
export const xmSmallViewportWidth = matchMedia('(min-width: 650px)') as MediaQueryList;
export const mSmallViewportWidth = matchMedia('(min-width: 700px)') as MediaQueryList;
export const smallViewportWidth = matchMedia('(min-width: 820px)') as MediaQueryList;
export const mediumViewportWidth = matchMedia('(min-width: 900px)') as MediaQueryList;
export const xMediumViewportWidth = matchMedia('(min-width: 1280px)') as MediaQueryList;
export const fullViewportWidth = matchMedia('(min-width: 1600px)') as MediaQueryList;
