import { createContext } from 'react';

export interface ModelParams {
    content: React.ReactNode;
    onClose: boolean;
    modelTransactionState?: boolean;
    borderColor?: string;
    width?: string;
    height?: string;
    padding?: string;
    topView?: boolean;
}

export type AppApi = {
    openModel: (modelParams: ModelParams) => void;
    modelTransactionState(): void;
    closeModal(): void;
};

export const AppContext = createContext<AppApi>({} as AppApi);
