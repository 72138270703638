import { HTMLAttributes } from 'react';

interface IconStyleProps {
    size?: number;
    color?: string;
    noColor?: boolean;
    cursor?: string;
}

export const DEFAULT_ICON_COLOR = '#494A50';

export type IconProps = IconStyleProps & Omit<any, 'ref'> & HTMLAttributes<any>;

export const styledIcon = (props: IconProps) => {
    const { size, color, cursor, style: generalStyles } = props;
    return {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        color: color,
        cursor,
        ...generalStyles,
    };
};
