export enum SUBMENU_VALIDATIONS {
    MY_CLUBS,
}

export interface Menu {
    path?: string;
    href?: string;
    title: string;
    dropdown?: boolean;
    visible?: boolean;
    submenu?: boolean;
    submenuValidate?: SUBMENU_VALIDATIONS.MY_CLUBS;
}

export interface GenericDropdown {
    title: string;
    action?: (title: string) => any;
}
