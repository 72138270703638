import React, { useCallback, useEffect, useState } from 'react';

import RKLTwitterIcon from '../../ui/icons/rkl-twitter';
import RKLLoadingSpinner from '../rkl-loading-spinner';
import buttonStyle from './rkl-button.module.css';

export interface ButtonProps {
    size: 'lgDesktop' | 'medDesktop' | 'square' | 'medium' | 'small' | 'mobile' | 'LgMobile' | 'twitter';
    squared?: boolean;
    color: 'violet' | 'transparent' | 'green' | 'white';
    borderOnly?: boolean;
    styles?: object;
    onClick: (e) => void;
    disabled?: boolean;
    decorator?: string;
    height?: number;
    width?: number;
    loading?: boolean;
}

const RKLButton: React.FC<ButtonProps> = (props) => {
    const {
        color,
        size,
        squared,
        borderOnly,
        styles,
        children,
        decorator,
        disabled,
        onClick,
        height,
        width,
        loading,
    } = props;
    const [btnColor, setBtnColor] = useState<string>();
    const [txtColor, setTxtColor] = useState<string>();
    const [btnHover, setBtnHover] = useState<boolean>(false);

    const setColors = useCallback(() => {
        if (disabled) return `${buttonStyle.disabled}`;
        switch (color) {
            case 'violet':
                if (borderOnly) {
                    return `${buttonStyle.borderOnlyViolet}`;
                } else {
                    setTxtColor(`${buttonStyle.fontColorViolet}`);
                    return `${buttonStyle.violetBG}`;
                }
            case 'white':
                if (borderOnly) {
                    return `${buttonStyle.borderOnlyWhite}`;
                } else {
                    setTxtColor(`${buttonStyle.fontColorBlack}`);
                    return `${buttonStyle.whiteBG} ${buttonStyle.fontColorBlack}`;
                }
            case 'green':
                setTxtColor(`${buttonStyle.fontColorGreen}`);
                return `${buttonStyle.greenBG}`;
            default:
                setTxtColor(`${buttonStyle.fontColorViolet}`);
                return `${buttonStyle.violetBG}`;
        }
    }, [setTxtColor, borderOnly, color, disabled]);

    useEffect(() => {
        setBtnColor(setColors);
    }, [color, borderOnly, setColors]);

    return (
        <button
            onClick={onClick}
            style={{ height: height, width: width, ...styles, padding: '0' }}
            className={`${buttonStyle.button} ${btnColor} 
                ${size === 'square' && buttonStyle.square}  
                ${size === 'medium' && buttonStyle.medium}  
                ${size === 'small' && buttonStyle.small}
                ${size === 'lgDesktop' && buttonStyle.lgDesktop}
                ${size === 'medDesktop' && buttonStyle.medDesktop}
                ${size === 'mobile' && buttonStyle.mobile}
                ${size === 'LgMobile' && buttonStyle.lgMobile}
                ${size === 'twitter' && buttonStyle.twitter}
                ${squared && buttonStyle.squared}
                ${loading && buttonStyle.loading}
                `}
            disabled={disabled}
            onMouseEnter={() => setBtnHover(true)}
            onMouseLeave={() => setBtnHover(false)}
        >
            {/* loading && background white, text #494a50, spinner center 22px margin top/bottom */}
            <span className={buttonStyle.btnSpan}>
                {loading ? (
                    <RKLLoadingSpinner height={35} width={35}>
                        {children}
                    </RKLLoadingSpinner>
                ) : (
                    children
                )}
                {decorator && (
                    <>
                        &nbsp;
                        <div className={`${buttonStyle.decorator} ${txtColor}`}>{decorator}</div>
                    </>
                )}
                {size === 'twitter' && (
                    <span className={buttonStyle.twitterButtonIcon}>
                        {btnHover ? (
                            <RKLTwitterIcon color="black" size={22} />
                        ) : (
                            <RKLTwitterIcon color={borderOnly ? '#ae80f0' : 'black'} size={22} />
                        )}
                    </span>
                )}
            </span>
        </button>
    );
};

export default RKLButton;
