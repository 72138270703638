import React from 'react';

import { IconProps, styledIcon } from '../../../utils/icons';

const RKLTimesFilledIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C8.95431 40 9.40953e-07 31.0457 0 20C3.13651e-07 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40ZM14.0534 14.0534C14.5782 13.5286 15.4291 13.5286 15.9539 14.0534L20.7049 18.8045L25.456 14.0534C25.9808 13.5286 26.8317 13.5286 27.3565 14.0534C27.8813 14.5782 27.8813 15.4291 27.3565 15.9539L22.6054 20.7049L27.3565 25.456C27.8813 25.9808 27.8813 26.8317 27.3565 27.3565C26.8317 27.8813 25.9808 27.8813 25.456 27.3565L20.7049 22.6054L15.9539 27.3565C15.4291 27.8813 14.5782 27.8813 14.0534 27.3565C13.5286 26.8317 13.5286 25.9808 14.0534 25.456L18.8045 20.7049L14.0534 15.9539C13.5286 15.4291 13.5286 14.5782 14.0534 14.0534Z"
                fill="#494A50"
            />
        </svg>
    );
};

export default RKLTimesFilledIcon;
