import styles from './rkl-loading-spinner.module.css'

interface RKLLoadingSpinnerProps {
    height: number;
    width: number;
}

const RKLLoadingSpinner: React.FC<RKLLoadingSpinnerProps> = ({
    height,
    width,
}) => {
    return (
        <div style={{width: width, height: height}} className={styles.spinnerWrapper}>
            <div className={styles.spinner} />
        </div>
    )
}

export default RKLLoadingSpinner